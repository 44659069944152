@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=latin-ext");

html {
  position: relative;
  min-height: 100%;
}

body {
  min-height: 100%;
  font-family: "Roboto Slab", serif;

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    margin: 0;
    padding: 0;
    font-weight: 300;
    color: @gray-darker;
  }

  p {
    font-size: 15px;
  }

  .fixed-button {
    position: fixed;
    right: -350px;
    height: 250px;
    top: 40%;
    z-index: 444;
    display: flex;
    align-items: center;
    transition: 500ms;

    &:hover {
      right: 0;
    }

    .button-wrapper {
      display: flex;
      align-items: center;

      .service-button {
        background-color: @color3;
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
        cursor: pointer;
        height: 50px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: pulse-animation-red 2s infinite;

        @keyframes pulse-animation-red {
          0% {
            box-shadow: 0 0 0 0px rgba(@color3, 0.5);
          }

          100% {
            box-shadow: 0 0 0 10px rgba(@color3, 0);
          }
        }

        p {
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
        }
      }

      .service-box {
        width: 350px;
        height: 250px;
        background-color: #f5f5f5;
        box-shadow: 0 0 10.4px 5.6px rgba(47, 47, 47, 0.07);
        padding: 30px;

        h3 {
          margin-bottom: 20px;
          font-size: 22px;
        }

        p {
          font-size: 15px;
          line-height: 28px;
        }

        .btn-default {
          margin-top: 20px;
        }
      }
    }
  }

  .section-heading {
    p {
      position: relative;
      font-size: 25px;
      line-height: 25px;
      display: inline-block;
      padding-left: 37px;

      &::before {
        position: absolute;
        content: "";
        top: 10px;
        left: 0;
        width: 30px;
        height: 8px;
        background: url(../img/pictures/heading-span.png) no-repeat;
      }
    }
  }

  .section-subpage-heading {
    width: 55%;
    padding: 15px;
    padding-right: 40px;
    background: @color3;
    text-align: right;
    margin-top: 50px;

    &.bg-yellow {
      background-color: #eda919;
    }

    &.bg-red {
      background-color: #d70b2d;
    }

    h2 {
      color: #fff;
    }

    &.heading-reverse {
      float: right;
      text-align: left;
      padding-right: 15px;
    }

    &.heading-red {
      background: @color2;
    }
  }

  .btn-default {
    font-size: 14px;
    background: @color3;
    border-radius: 25px;
    padding: 6px 20px;
    border: 1px solid @color3;
    color: #fff;
    transition: all 0.3s;

    &:hover {
      border-color: @color3;
      background: #fff;
      color: @color3;
    }
  }

  .btn-red {
    background: @color2;
    border: 1px solid @color2;

    &:hover {
      border-color: @color2;
      color: @color2;
    }
  }

  .btn-orange {
    background: @color1;
    border: 1px solid @color1;

    &:hover {
      border-color: @color1;
      color: @color1;
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-25 {
    margin-top: 25px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-25 {
    margin-bottom: 25px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .p-t-5 {
    padding-top: 5px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-15 {
    padding-top: 15px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-25 {
    padding-top: 25px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-15 {
    padding-bottom: 15px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-25 {
    padding-bottom: 25px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }

  .p-120 {
    padding: 120px 0;
  }
}

div#section-header-contact {
  border-bottom: 1px solid @gray-lighter;

  .contact-flexbox {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .flexbox-info {
      display: flex;
      align-items: center;
    }

    a {
      color: lighten(@gray-light, 10%);
      font-size: 15px;

      img {
        max-height: 20px;
        margin-right: 8px;
      }

      &.flexbox-margin {
        margin-right: 20px;
      }
    }

    .button-wrapper-mobile {
      display: none;
      justify-content: space-around;
      margin-top: 10px;
    }

    .btn-red {
      &:hover {
        background: @color2;
      }
    }

    .flexbox-partner {
      display: flex;
      align-items: center;

      a {
        img {
          padding-left: 15px;
        }
      }
    }
  }
}

div#section-header {
  nav.navbar {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#3ea527+0,208642+100 */
    background: rgb(62, 165, 39);
    /* Old browsers */
    background: -moz-linear-gradient(
      left,
      rgba(62, 165, 39, 1) 0%,
      rgba(32, 134, 66, 1) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      left,
      rgba(62, 165, 39, 1) 0%,
      rgba(32, 134, 66, 1) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to right,
      rgba(62, 165, 39, 1) 0%,
      rgba(32, 134, 66, 1) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3ea527', endColorstr='#208642', GradientType=1);
    /* IE6-9 */
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    min-height: auto;

    .navbar-brand {
      height: auto;

      img {
        max-height: 60px;
      }
    }

    ul.navbar-nav {
      li {
        a {
          font-size: 17px;
          padding: 35px 20px;
          color: @gray-lighter;
          transition: all 0.3s;

          &:hover {
            box-shadow: inset 0 -2px 0 0 darken(@color2, 5%);
          }
        }

        &.divider {
          padding: 35px 5px;
          color: @color3;
          cursor: default;
          display: none;
        }
      }
    }

    .navbar-toggle {
      padding: 10px;
      border-radius: 0;
      background: none;
      border: 1px solid darken(@color4, 10%);
      margin-top: 25px;

      .icon-bar {
        width: 22px;
        height: 3px;
        border-radius: 3px;
        background-color: #fff;
      }
    }
  }
}

div#section-welcome {
  background: url(../img/backgrounds/fotolia_85314627.jpg);
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;

  .welcome-chevron {
    position: absolute;
    z-index: 999;
    bottom: 35px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;

    span {
      color: darken(@color2, 10%);
      font-size: 50px;
      font-weight: 800;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 15px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    /* IE6-9 */
  }

  /* 	&:after {
	content: '';
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 15px;
	Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100
	background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%); FF3.6-15
	background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%); Chrome10-25,Safari5.1-6
	background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); IE6-9
} */

  img.img-products {
    position: absolute;
    bottom: 0;
    left: 50px;
    max-width: 700px;
  }

  .welcome-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-main-carousel {
      width: 72%;
      height: 440px;
      overflow: hidden;

      .owl-carousel {
        height: 100%;

        .item {
          height: 440px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          h1 {
            font-size: 35px;
            font-weight: 400;
            color: #fff;
            text-shadow: 2px 2px 2px rgba(0, 159, 227, 0.6);

            strong {
              color: @color2;
              text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
            }
          }

          p {
            margin-top: 20px;
            color: #fff;
            font-size: 18px;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
            width: 75%;
          }

          .btn {
            margin-top: 20px;
          }
        }

        .owl-controls {
          display: none;
        }
      }
    }

    .flexbox-banners {
      width: 25%;
      height: 390px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .banner-service {
        background: rgba(255, 255, 255, 0.8);
        padding: 10px;
        height: 45%;
        position: relative;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.02),
          0 3px 3px rgba(0, 0, 0, 0.06);
        border-radius: 0 0 0 20px;

        h2 {
          font-size: 25px;

          span {
            font-weight: 700;

            &.dog {
              color: @color3;
            }

            &.cat {
              color: @color2;
            }
          }
        }

        p {
          width: 80%;
          margin-top: 10px;
        }

        .btn {
          position: absolute;
          bottom: 10px;
          left: 10px;
        }

        .img-absolute {
          position: absolute;
          bottom: 10%;
          right: -30px;
          max-height: 80%;
        }
      }
    }
  }
}

div#section-about {
  .about-partner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
      font-size: 22px;
    }

    h3 {
      margin: 20px 0;
      font-size: 20px;
      font-weight: 600;
    }

    img {
      &.logo {
        width: 50%;
      }

      &.border-top {
        border-top: 2px solid @color2;
      }
    }

    p {
      margin-top: 10px;
      text-align: center;
    }

    .btn {
      margin-top: 20px;
      width: 93%;
    }
  }

  .about-section-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .section-description {
      width: 55%;

      b {
        color: @color2;
        font-size: 20px;
      }
    }

    .section-image {
      position: relative;
      width: 42%;
      padding-top: 45%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .btn-offer {
    border-radius: 25px 25px 0 0;
    padding-top: 10px;
    font-size: 16px;
  }

  .about-products {
    background: @gray-lighter;
    box-shadow: inset 0 3px 0 0 @color3;
    padding: 3px;

    .carousel {
      .item {
        height: auto;
        background: #fff;
        padding: 50px;

        img {
          max-height: 170px;
        }

        h4 {
          font-size: 25px;

          span {
            font-weight: 700;

            &.dog {
              color: @color3;
            }

            &.cat {
              color: @color2;
            }
          }
        }

        p {
          margin-top: 10px;
        }

        .price {
          p {
            font-weight: 700;
            font-size: 22px;
            color: @color1;
          }
        }

        .btn {
          margin-top: 20px;
        }
      }

      ol.carousel-indicators {
        bottom: 0;

        li {
          background-color: @color1;
          border: none;
        }
      }

      .carousel-control {
        background: none;
        width: 8%;
      }
    }
  }

  .advantage-service {
    text-align: center;

    img {
      margin: 0 auto;
      max-height: 100px;
    }

    h3 {
      font-size: 25px;
      margin: 10px 0;
      font-weight: 700;
    }

    p {
      padding: 10px;
      background: @gray-lighter;
    }
  }
}

div#section-cooperate {
  .cooperate-shops {
    background-image: url(../img/backgrounds/worldmap.png);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    background-color: @color4;
    height: 200px;
    width: 50%;
    padding: 20px;
    float: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .section-heading {
      text-align: center;

      p {
        padding-left: 0;

        &::before {
          position: absolute;
          content: "";
          top: 10px;
          left: -37px;
          width: 30px;
          height: 8px;
          background: url(../img/pictures/heading-span.png) no-repeat;
        }

        &::after {
          position: absolute;
          content: "";
          top: 10px;
          right: -37px;
          width: 30px;
          height: 8px;
          background: url(../img/pictures/heading-span.png) no-repeat;
        }
      }
    }

    .section-description {
      p {
        text-align: center;
        font-size: 25px;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .cooperate-service {
    height: 200px;
    float: left;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;

    h4 {
      font-size: 25px;
      color: #fff;
      font-weight: 700;
    }

    p {
      color: #fff;
      font-weight: 700;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    }

    .btn {
      margin-top: 20px;
    }

    &.retail {
      background: url(../img/backgrounds/fotolia_114490382.jpg) center;
      background-size: cover;
    }

    &.wholesale {
      background: url(../img/backgrounds/fotolia_118484936.jpg) center;
      background-size: cover;
    }
  }
}

div#section-subpage-welcome {
  background: url(../img/backgrounds/fotolia_120683162.jpg);
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  padding: 120px 0;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 15px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    /* IE6-9 */
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
    /* IE6-9 */
  }

  .welcome-flexbox {
    .flexbox-main-carousel {
      width: 80%;
      overflow: hidden;

      .owl-carousel {
        .item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          h1 {
            font-size: 35px;
            font-weight: 400;
            color: #fff;
            text-shadow: 2px 2px 2px rgba(0, 159, 227, 0.6);

            strong {
              color: @color2;
              text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
            }
          }

          p {
            margin-top: 20px;
            color: #fff;
            font-size: 18px;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
            width: 50%;
          }

          .btn {
            margin-top: 20px;
          }
        }

        .owl-controls {
          display: none;
        }
      }
    }
  }
}

div#o-karmie-max-section-content {
  .section-description {
    h3 {
      font-size: 30px;

      strong {
        color: @color2;
      }
    }
  }

  .col-md-6 {
    img {
      width: 80%;
    }
  }

  .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      object-fit: contain;

      // &:first-of-type {
      // 	width: 57%;
      // }

      // &:nth-of-type(2) {
      // 	width: 43%;
      // }
    }
  }

  .content-flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    img {
      width: 25%;
      border: 5px solid @color1;
    }

    .section-description {
      width: 65%;
      text-align: center;

      h3 {
        font-size: 30px;
      }

      p {
        margin-top: 10px;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

div#nasze-produkty-section-content {
  .category-links {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .category-link {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 40px;
      margin: 0 30px;
      background-color: @color3;
      font-size: 26px;
      font-weight: 700;
      text-align: center;
      transition: all 200ms;
      min-height: 85px;
      color: #fff;

      &:nth-of-type(2) {
        background-color: @color2;

        &:hover,
        &:focus,
        &:active,
        &.active {
          background-color: darken(@color2, 7%);
          color: #fff;
          text-decoration: none;
        }
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        background-color: darken(@color3, 10%);
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .description-content {
    padding: 30px 0 50px 0;
    text-align: center;

    p {
      line-height: 28px;
      margin-top: 10px;
    }

    h3 {
      font-size: 36px;
      margin-bottom: 20px;
    }
  }

  .description-content-image {
    padding: 30px 0 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      width: 50%;

      p {
        line-height: 28px;
        margin-top: 10px;
      }

      h3 {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;
        font-weight: 600;
      }
    }

    .image {
      position: relative;
      width: 45%;
      padding-top: 45%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .products-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -25px;
    width: 100%;

    .product-service {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 75px;

      .service-image {
        width: 40%;
        min-width: 220px;
        max-width: 285px;
      }

      .service-description {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        text-align: center;

        h4 {
          font-size: 25px;

          span {
            font-weight: 700;
            display: inline-block;
            margin-bottom: 10px;

            &.blue {
              color: @color3;
            }

            &.yellow {
              color: #eda919;
            }

            &.red {
              color: @color2;
            }

            &.name {
              font-weight: 400;
            }

            &.cat {
              color: @color2;
            }
          }
        }

        p {
          margin-top: 10px;
          line-height: 28px;

          &:last-of-type {
            margin-bottom: 20px;
          }
        }

        .price {
          p {
            font-weight: 700;
            font-size: 22px;
            color: @color1;
          }
        }

        .btn {
          margin-top: auto;
          justify-self: flex-end;
          align-self: center;
        }
      }
    }
  }
}

div#wspolpraca-section-content {
  .about-partner {
    text-align: center;

    h2 {
      font-size: 22px;
    }

    h3 {
      margin: 20px 0;
      font-size: 20px;
      font-weight: 600;
    }

    .logo-wrapper {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid @color3;

      img {
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
    }

    // .partner-image {
    // 	border-top: 2px solid @color2;
    // 	overflow: hidden;
    // 	// height: 200px;
    // 	display: flex;
    // 	flex-direction: column;
    // 	align-items: center;
    // 	text-align: center;
    // }

    // img {
    // 	&.logo {
    // 		width: 50%;
    // 	}

    // 	&.border-top {
    // 		border-top: 2px solid @color2;
    // 	}
    // }

    p {
      margin-top: 10px;
      text-align: center;
    }

    .btn {
      margin-top: 10px;
      width: 100%;
    }
  }

  .cooperate-shops {
    background-image: url(../img/backgrounds/worldmap.png);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
    background-color: @color4;
    height: 160px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .section-heading {
      text-align: center;

      p {
        padding-left: 0;

        &::before {
          position: absolute;
          content: "";
          top: 10px;
          left: -37px;
          width: 30px;
          height: 8px;
          background: url(../img/pictures/heading-span.png) no-repeat;
        }

        &::after {
          position: absolute;
          content: "";
          top: 10px;
          right: -37px;
          width: 30px;
          height: 8px;
          background: url(../img/pictures/heading-span.png) no-repeat;
        }
      }
    }

    .section-description {
      p {
        text-align: center;
        font-size: 25px;
        color: #fff;
        font-weight: 700;
      }
    }
  }

  .cooperate-service {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 25px;
      color: #fff;
      font-weight: 700;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    }

    p {
      color: #fff;
      font-weight: 700;
      margin-top: 20px;
      text-align: center;
      padding: 10px;
      background: rgba(0, 0, 0, 0.5);
    }

    .btn {
      margin-top: 20px;
    }

    &.retail {
      background: url(../img/backgrounds/fotolia_114490382.jpg) center;
      background-size: cover;
    }

    &.wholesale {
      background: url(../img/backgrounds/fotolia_118484936.jpg) center;
      background-size: cover;
    }
  }
}

div#kontakt-section-content {
  a {
    font-size: 25px;
    font-weight: 700;
    color: @color2;
  }

  iframe {
    border: none;
    width: 100%;
    height: 320px;
  }

  address {
    line-height: 28px;
    margin: 10px 0;
    font-size: 16px;
  }

  p ~ span {
    font-size: 16px;
    margin-top: 10px;
    display: block;
  }

  form {
    input,
    textarea {
      border: 1px solid @color3;
      box-shadow: none;
    }

    input {
      height: 40px;
    }

    textarea {
      height: 130px;
    }
  }
}

div#section-footer {
  background: #444;

  .footer-blockquote {
    padding: 20px;
    background-image: url(../img/pictures/footer-blockquote.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    background-color: #2c6197;
    border-radius: 4px;
    padding-left: 100px;

    h4,
    p {
      font-weight: 400;
      color: #fff;
    }
  }

  .offer-heading {
    p {
      font-size: 25px;
      text-align: right;
      color: #fff;
    }
  }

  .footer-offer {
    background: #fff;
    border-radius: 0 0 20px 20px;
  }

  .footer-service {
    background: #fff;
    padding: 10px;
    position: relative;
    border-radius: 0 0 0 20px;

    h2 {
      font-size: 23px;
      line-height: 30px;

      span {
        font-weight: 700;

        &.dog {
          color: @color3;
        }

        &.cat {
          color: @color2;
        }
      }
    }

    p {
      width: 80%;
      margin-top: 10px;
    }

    .btn {
      margin-top: 30px;
    }

    .img-absolute {
      position: absolute;
      bottom: -10px;
      right: -20px;
      max-height: 120%;
    }

    &.service-reverse {
      text-align: right;
      border-radius: 0 0 20px 0;

      p {
        float: right;
      }

      .img-absolute {
        left: 0px;
        max-height: 70%;
      }
    }
  }

  .section-heading-small {
    p {
      color: @color1;
      margin-bottom: 5px;
    }
  }

  .footer-contact {
    a {
      color: lighten(@gray-light, 25%);
      font-size: 18px;

      img {
        max-height: 20px;
        margin-right: 8px;
        margin-top: -4px;
      }
    }

    .media {
      img {
        max-width: 70px;
      }

      h4 {
        color: #fff;
        font-size: 17px;
      }

      p {
        color: @color1;
        font-weight: 400;
      }
    }
  }

  .footer-sitemap {
    ul {
      padding-left: 16px;
      list-style-image: url(../img/pictures/footer-ul.png);

      li {
        padding-bottom: 5px;
        border-bottom: 1px solid #555;
        margin-top: 5px;

        a {
          color: lighten(@gray-light, 25%);
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
          border: none;
        }
      }
    }
  }

  .footer-divider-hor {
    width: 1px;
    height: 220px;
    background: #555;
    margin: 0 auto;
  }

  .footer-divider {
    height: 1px;
    background: #555;
    width: 100%;
  }
}

@media (max-width: @screen-sm-max) {
  .sm-m-t-20 {
    margin-top: 20px;
  }

  .sm-m-t-30 {
    margin-top: 30px;
  }

  div#section-header {
    nav.navbar {
      .navbar-collapse {
        box-shadow: none;
        border: none;
      }
    }
  }

  div#section-welcome {
    .welcome-flexbox {
      .flexbox-main-carousel {
        width: 100%;
        height: 280px;

        .owl-carousel {
          height: 100%;

          .item {
            height: 280px;

            h1 {
              font-size: 25px;
            }

            p {
              font-size: 14px;
              display: none;
            }
          }
        }
      }
    }
  }

  div#section-about {
    .about-products {
      .carousel {
        .item {
          height: auto;
          padding: 50px 20px;
        }
      }
    }

    .about-section-wrapper {
      .section-description {
        width: 60%;
      }

      .section-image {
        width: 35%;
      }
    }
  }

  div#section-cooperate {
    .cooperate-shops {
      height: auto;
      width: 100%;

      .section-description {
        margin: 20px 0;
      }
    }

    .cooperate-service {
      height: 200px;
      float: left;
      width: 50%;
    }
  }

  div#section-subpage-welcome {
    padding: 20px 0;

    .welcome-flexbox {
      .flexbox-main-carousel {
        .owl-carousel {
          height: 200px;

          .item {
            height: 200px;

            h1 {
              font-size: 22px;
            }

            p {
              display: none;
            }
          }
        }
      }
    }
  }

  div#o-karmie-max-section-content {
    .section-subpage-heading {
      width: 85%;
    }

    .content-flexbox {
      justify-content: center;
      flex-direction: column;
      margin-top: 50px;

      .section-description {
        width: 100%;
      }

      img {
        width: 50%;
        margin-bottom: 20px;
      }

      &.flexbox-reverse {
        flex-direction: column-reverse;
      }
    }
  }

  div#nasze-produkty-section-content {
    .section-subpage-heading {
      width: 90%;
    }

    .products-flexbox {
      .product-service {
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 48%;
        height: 100%;

        .service-image {
          width: 60%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 15px;
        }

        .service-description {
          margin-left: auto;
          margin-right: auto;

          .btn {
            align-self: center;
          }
        }
      }
    }

    .description-content-image {
      padding: 0 0 50px 0;
      flex-direction: column;
      justify-content: center;

      .text {
        order: 2;
        width: 100%;
      }

      .image {
        width: 100%;
        padding-top: 70%;
        order: 1;
      }
    }
  }

  div#wspolpraca-section-content {
    .cooperate-shops {
      height: auto;

      .section-description {
        margin: 20px 0;
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  .xs-m-t-30 {
    margin-top: 30px;
  }

  div#section-header-contact {
    .contact-flexbox {
      justify-content: center;
      text-align: center;

      .flexbox-info {
        width: 100%;
        justify-content: center;
      }

      .contact-link-wrapper {
        display: none;
      }

      .button-wrapper-mobile {
        display: flex;
        width: 100%;
        margin-top: 0;
      }

      a {
        &.flexbox-margin {
          margin-right: 0px;
        }
      }
    }
  }

  div#section-about {
    .about-products {
      .carousel {
        .item {
          .media,
          .media-left,
          .media-body {
            display: block;
            text-align: center;
            width: auto;
          }

          h4 {
            margin-top: 20px;
          }
        }
      }
    }

    .about-section-wrapper {
      flex-direction: column;
      align-items: center;

      .section-description {
        width: 100%;
      }

      .section-image {
        margin-top: 30px;
        width: 55%;
        padding-top: 80%;
      }
    }
  }

  div#nasze-produkty-section-content {
    .category-links {
      flex-wrap: wrap;

      .category-link {
        width: 75%;
        max-width: 300px;
        padding: 10px;
        font-size: 24px;

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }

    .section-subpage-heading {
      width: 90%;
    }

    .products-flexbox {
      .product-service {
        width: 100%;
      }
    }
  }
}
